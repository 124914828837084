/**
 * import
 *
 * @version 0.1 (2021.05.13, 05.17 : usayama)
 * @version 0.2 (2021.06.22 : usayama) // NewsPagerContainer に変更
 */
import React from 'react'
import { Router } from '@reach/router'
// conf, action
// components
// containers
import LayoutContainer from '@containers/LayoutContainer'
import MypointContainer from '@containers/MypointContainer'
import NavlinkContainer from '@containers/NavlinkContainer'
import NewsDetailContainer from '@containers/NewsDetailContainer'
import NewsPagerContainer from '@containers/NewsPagerContainer'
// helper
// style, img, elm
// const

/**
 * const
 *
 * @version 0.1 (2021.05.13, 05.17 : usayama)
 * @version 0.2 (2021.06.22 : usayama) // NewsPagerContainer に変更
 */
const News = () => (
  	<LayoutContainer>
      {/* Seo は NewsDetailContainer へ追加 */}
  	  <MypointContainer />
  	  <NavlinkContainer />
      <Router basepath='/news'>
        <NewsDetailContainer path='/:url' />
      </Router>
  	  <NewsPagerContainer />
  	</LayoutContainer>
)

/**
 * export
 *
 * @version 0.1 (2021.05.13, 05.17 : usayama)
 */
export default News