/** 
 * import : 
 * 
 * @version 0.1 (2021.05.17 : usayama_dx)
 * @version 0.2 (2021.06.22 : usayama) // 実装
*/
import React from 'react'
import { Link } from 'gatsby'
import { useSelector } from 'react-redux'
// conf, action
import { APP_ROUTE } from '@config/routers'
// components
// containers
// helper
// style, img
// consts
const moment = require('moment')

/** 
 * const : 
 * 
 * @version 0.1 (2021.05.17 : usayama_dx)
*/
export default function NewsPagerContainer(props) {

  /** 
   * const : 
   * 
   * @version 0.1 (2021.05.17 : usayama_dx)
   */
  const { information } = useSelector(state => state.Information)

  /** 
   * return : 
   * 
   * @version 0.1 (2021.05.17 : usayama_dx)
   * @version 0.2 (2021.06.22 : usayama) // 実装
   * @version 0.3 (2021.06.30 : usayama) // className= に修正
   */
  return (
    <div className="news-pager wrap">
      <div className="container">
      {
        information.prev && <dl>
          <dt>{moment(information.prev.publish_date).format('YYYY-MM-DD')}</dt>
          <dd><Link to={APP_ROUTE.information + information.prev.url}>{information.prev.title}</Link></dd>
        </dl>
      }
      {
        information.next && <dl>
          <dt>{moment(information.next.publish_date).format('YYYY-MM-DD')}</dt>
          <dd><Link to={APP_ROUTE.information + information.next.url}>{information.next.title}</Link></dd>
        </dl>
      }
      </div>
    </div>
  )
}
