/** 
 * import : 
 * 
 * @version 0.1 (2021.05.13 : usayama)
 * @version 0.2 (2021.06.11 : usayama) // APP_ROUTE 追加
*/
import React from 'react'
import { Link } from 'gatsby'
import { AiOutlineArrowRight } from "react-icons/ai"
// conf, action
import { APP_ROUTE } from '@config/routers'
// components
import LabelHtml from '@components/Typo/LabelHtml'
// containers
// helper
// style, img, elm
// const

/** 
 * const : 
 * 
 * @version 0.1 (2021.05.13 : usayama)
*/
export default function Navlink(props) {

  /** 
   * return : 
   * 
   * @version 0.1 (2021.05.13 : usayama)
   * @version 0.2 (2021.06.11 : usayama) // icon 削除、メニュー調整
   * @version 0.3 (2021.09.29 : usayama) // メニュー実装
   * @version 0.4 (2023.05.07 : usayama) // メニュー変更
   * @version 0.5 (2024.08.28 : usayama) // メニュー変更
   */
  return (
    <div className="navlink">
      <div className="container">
        <ul>
          <li><Link to={APP_ROUTE.menu01}><LabelHtml label="navlink.menu01" /><AiOutlineArrowRight size={22} /></Link></li>
          <li><Link to={APP_ROUTE.menu02}><LabelHtml label="navlink.menu02" /><AiOutlineArrowRight size={22} /></Link></li>
          <li><Link to={APP_ROUTE.menu03}><LabelHtml label="navlink.menu03" /><AiOutlineArrowRight size={22} /></Link></li>
          <li><Link to={APP_ROUTE.menu04}><LabelHtml label="navlink.menu04" /><AiOutlineArrowRight size={22} /></Link></li>
        </ul>
      </div>
    </div>
  )
}
