/** 
 * import : 
 * 
 * @version 0.1 (2021.05.13 : usayama)
 * @version 0.2 (2021.06.22 : usayama) // 実装
 * @version 0.3 (2021.06.28 : usayama) // Seo 追加
*/
import React from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useTranslation } from 'react-i18next'
// import { HiHome } from "react-icons/hi"
// conf, action
import informationActions from '@redux/information/actions'
// components
import Seo from '@components/Tools/Seo'
import Label from '@components/Typo/Label'
// containers
// helper
import { isEmpty } from '@lib/helpers'
// style, img, elm
const iconImg = require("@assets/images/title-icon-informations.png")
// const
const { initGetInformation, getInformation } = informationActions
const moment = require('moment')

/** 
 * const : 
 * 
 * @version 0.1 (2021.05.13 : usayama)
*/
export default function NewsDetailContainer(props) {

  /** 
   * const : 
   * 
   * @version 0.1 (2021.05.13 : usayama)
   * @version 0.2 (2021.06.22 : usayama) // 実装
   * @version 0.3 (2021.06.22 : usayama) // useTranslation 追加
   */
  const dispatch = useDispatch()
  const { t } = useTranslation()
  const { information } = useSelector(state => state.Information)
  const { url } = props

  /** 
   * useEffect
   * 
   * @version 0.1 (2021.05.13 : usayama)
   * @version 0.2 (2021.06.14 : usayama) // 実装
   */
  React.useEffect(() => {
    dispatch(initGetInformation())
    if(url) {
      dispatch(getInformation(url))
    }
  }, [url, dispatch])

  /** 
   * return : 
   * 
   * @version 0.1 (2021.05.13 : usayama)
   * @version 0.2 (2021.06.28 : usayama) // Seo 追加
   */
  return (
    <div className="news wrap">
    { !isEmpty(information) ? <Seo title={information.title} /> : <Seo title={t('title.news')} /> }
      <div className="container">
        <div className="title title-h-line">
          <h3><Label label="title.informations" /></h3>
          <div className="h-line">
            <span></span>
            <img src={iconImg.default} alt="" />
            <span></span>
          </div>
        </div>
        { 
          !isEmpty(information) ? 
            <div className="news-detail">
              <div className="title">
                <h3>{information.title}</h3>
                <div>{moment(information.publish_date).format('YYYY-MM-DD')}</div>
              </div>
              <div className="text" dangerouslySetInnerHTML={{ __html: information.text }} />
              <div className="row">
              {
                information.files.map((file, i) => <div className="col-4"><img src={file} alt="" className="img-fluid" /></div>)
              }
              </div>
            </div>
          : <div><Label label="text.no_information" /></div>
        }
      </div>
    </div>
  )
}
